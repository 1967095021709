<script setup lang="ts">

</script>

<template>
    <div class="base-input">
        <slot></slot>
    </div>
</template>

<style scoped>

</style>
